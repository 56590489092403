<template>
  <main>
    <b-container>
      <p class="main-title">
        Bemutatkozás
      </p>
      <b-card>
        <b-media no-body>
          <b-media-aside
            vertical-align="top"
            class="mx-auto"
          >
            <b-card
              img-src="../assets/images/rolunk/dr-sotonyi-kata.jpg"
              img-top
              tag="article"
              class="text-center"
            >
              <b-card-title class="main-card-title1">
                Dr. Sótonyi Kata
              </b-card-title>
              <b-card-title class="main-card-title2">
                Állatorvos
              </b-card-title>
              <b-card-text class="main-card-title3">
                Viza-Vet Állatorvosi Rendelő tulajdonosa
              </b-card-text>
            </b-card>
          </b-media-aside>

          <b-media-body class="ml-3">
            <p class="main-text">
              Kisgyerekkorom óta állatorvos szerettem volna lenni és boldog vagyok, hogy ez az álmom teljesült, mert a
              világ leggyönyörűbb hivatását gyakorolhatom, hiszen nincs szebb dolog mint állatokat gyógyítani, életeket
              menteni, fájdalmat enyhíteni. Az állat szeretetem mellett bizonyára nagy hatással volt rám Édesapám is,
              aki 40 éve állatorvos, és az Állatorvostudományi Egyetem rektora.
            </p>
            <p class="main-text">
              2009-ben szereztem <b>állatorvos doktori diplomámat</b> és azóta szakmai tapasztalataimra több állatorvos
              mellett dolgozva tettem szert, akik kiváló tanító mesteriem voltak. 2008-ban USA-ban, South Bend-ben, dr.
              Martin Langhofer-nél töltöttem el két hónapot, egy nagy forgalmú vegyes praxissal foglalkozó klinikán.
              2009 óta a Zuglói Állatorvosi Rendelőben részmunkaidőben, majd helyettesítő állatorvosként vállaltam
              munkát. 2010-ben Floridában, Boca Raton-ban dr. Poduszló Lászlónál a Miznervet állatorvosi klinikán
              dolgoztam, mint gyakornok, ahol betekintést nyerhettem egy amerikai kisállatrendelőbe. 2012-től a Mancsok
              Állatorvosi Rendelő alkalmazottjaként, olyan kiváló állatorvos mellett, mint Dr. Csikós Károly,
              tanulhattam meg azt az állatorvosi szemléletet és műtéti technikát, amit követni szeretnék és azóta is
              követek.
            </p>
            <p class="main-text">
              További két éves tanulást követően 2017-ben szereztem meg
              <b>kisállatgyógyász klinikus szakállatorvosi diplomámat</b>, hogy megnyissam a számomra legigényesebben
              felszerelt saját állatorvosi rendelőmet, amely egy igazi ékszerdoboz a Vizafogó területén. Legfőbb érdeklődési területem a mindennapi betegellátás
              mellett, a lágyrész sebészet. Elsősorban bejelentkezést követően várom azokat a felelősen gondolkodó
              állattartókat, akik fontosnak tartják kedvenceik rendszeres állatorvosi kontrollját, kivizsgálását.
            </p>
            <p class="main-text">
              <b>Köszönetnyilvánítás</b>
            </p>
            <p class="main-text">
              Szerencsésnek mondhatom magam, hogy Szüleim és Testvérem mindenben mellettem állnak és hisznek bennem.
              Szeretném megköszönni családomnak, Édesanyámnak, Édesapámnak és Testvéremnek, hogy bíztattak, támogattak
              és segítettek álmaim állatorvosi rendelőjének, a Viza-Vet Állatorvosi Rendelőnek a megvalósításában. Örök
              hálával tartozom Édesapámnak, aki a legkitartóbb ember a világon, aki nekem példakép, amiért céljait
              fáradhatatlan munkájával mindig eléri, büszke vagyok sikereire és szerencsés vagyok, hogy én lehetek a
              lánya. Köszönöm Szüleimnek, hogy becsületes, szeretni tudó, állatszerető felnőtté neveltek, bízom benne,
              hogy példájukat követve ugyanazzal a kitartással és alázattal végzem hivatásom, ahogyan ők.
            </p>
          </b-media-body>
        </b-media>
      </b-card>

      <b-card>
        <b-media no-body>
          <b-media-aside
            vertical-align="top"
            class="mx-auto"
          >
            <b-card
              img-src="../assets/images/rolunk/dr-biro-petra.jpg"
              img-top
              tag="article"
              class="text-center"
            >
              <b-card-title class="main-card-title1">
                dr. Biró Petra
              </b-card-title>
            </b-card>
          </b-media-aside>

          <b-media-body class="ml-3">
            <p class="main-text">
              2022-ben szereztem meg diplomámat az Állatorvostudományi Egyetemen, majd 2 hónapot a Lisszaboni Egyetem Sebészeti Tanszékén töltöttem. Végzős évem alatt egy svéd kisállatklinikán is tanulhattam. Főbb érdeklődési területeim a lágysebészet, fogászat, bőrgyógyászat. Ezeken a területeken belül rendszeres továbbképzések segítségével bővítem a tudásom. 2023 óta a Magyar Bőrgyógyász Állatorvosok Egyesületének pártoló tagja vagyok. Céljaim között szerepel a Fear Free akkreditáció megszerzése, hogy minél kevésbé legyen stresszes az állatorvosi látogatás mind a gazdiknak, mind kiskedvenceiknek. Fontosnak tartom a megfelelő és empatikus kommunikációt.
            </p>
          </b-media-body>
        </b-media>
      </b-card>

      <b-card>
        <b-media no-body>
          <b-media-aside
            vertical-align="top"
            class="mx-auto"
          >
            <b-card
              img-src="../assets/images/rolunk/dr-valker-adam.jpg"
              img-top
              tag="article"
              class="text-center"
            >
              <b-card-title class="main-card-title1">
                Dr. Valker Ádám
              </b-card-title>
            </b-card>
          </b-media-aside>

          <b-media-body class="ml-3">
            <p class="main-text">
              2016-ban végeztem az Állatorvostudományi Egyetemen, majd ezt követően a Magyar Állatorvosi Kamaránál szereztem meg praxisvezetői szakállatorvosi diplomám. Rengeteg fajta állatom volt életem során, ezek közül a kutyák és macskák gyógyításával töltöm mindennapjaim. Több generációtól van és volt is szerencsém tanulni, munkámban elkötelezett, lelkiismeretes vagyok, célom a folyamatos fejlődés, egyik kedvelt szakterületem a szemészet.
            </p>
          </b-media-body>
        </b-media>
      </b-card>

      <b-card>
        <b-media no-body>
          <b-media-aside
            vertical-align="top"
            class="mx-auto"
          >
            <b-card
              img-src="../assets/images/rolunk/csaba-kinga.jpg"
              img-top
              tag="article"
              class="text-center"
            >
              <b-card-title class="main-card-title1">
                Csaba Kinga
              </b-card-title>
            </b-card>
          </b-media-aside>

          <b-media-body class="ml-3">
            <p class="main-text">
              2024-ben végeztem el az állatorvosi asszisztens képzést, majd ugyanebben az évben csatlakoztam a Viza-Vet csapatához. Munkámat lelkiismeretesen végzem és törekszem arra, hogy minden vizsgálat a lehető leggördülékenyebben menjen. Fontos számomra a szakmai fejlődés, így szabadidőmben szívesen veszek részt továbbképzéseken is. Két, menhelyről örökbefogadott macskám van, Bogyó és Zsömi.
            </p>
          </b-media-body>
        </b-media>
      </b-card>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "Rolunk"
}
</script>

<style lang="scss" scoped>
@import '../sass/settings';

.card, img {
  border-radius: 0;
  border: none;
}

.card {
  margin: 30px 0;

  .card {
    background-color: $primary;
    margin: 0;
  }
}

@include media-breakpoint-down(md) {
  .media {
    flex-direction: column;

    .card {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(sm) {
  main {
    background-color: #fff;

    .main-title {
      margin-left: 20px;
    }
  }
  .container {
    margin: 0;
    padding: 0;

    & > .card {
      width: calc(100vw - 20px);
      margin: 0;
      padding: 0;
    }
  }
}
</style>
